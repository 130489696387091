import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
    return (
        <main className="home">
                <img src={require ("../images/Image1.png")} className="image-1" alt="Computer screen with building blocks" />
            <section className="main-row-1">
                <h1>Welcome to R Module</h1>
                <h2>A guide designed to get you using R for your analysis in no time at all.</h2>
                <Link to="/rmodule"><button>Get Started</button></Link>
            </section>
                <img src={require ("../images/Image2.png")} className="image-2" alt="Graph with hashtag tags" />
            <section className="main-row-2">
                <h3>Why learn R?</h3>
                <p>
                    R is one of the most popular and well-used statistical softwares available. It is used globally and has extraordinary scope in its functionality. It is for this reason that R is increasingly becoming the software to use in all aspects of statistical analysis. To know R will put you in a great position for any future direction you choose - this module, and R, is for everyone.
                </p>
            </section>
            <img src={require ("../images/Image3.png")} className="image-3" alt="RStudio in a computer" />
            <section className="main-row-3">
                <h3>What is R Module?</h3>
                <p>
                R Module has both a written manual and accompanying videos (see pdf and linked videos below) to suit all types of learning. The videos have been created to show you R in action, while the notes, especially the example code, are comprehensive and detailed and available for you to quickly and conveniently refer to whenever you need. 
                </p>
            </section>
            <img src={require ("../images/Image4.png")} className="image-4" alt="RStudio in a computer" />
            <section className="main-row-4">
                <h3>Who are we?</h3>
                <p>
                    We are two learners and teachers of R who are very passionate about encouraging and supporting others to use R for their statistical analysis! We have created R Module to make learning and using R a positive, confidence-building and enjoyable journey for everyone, with no expectation of statistical or computational backgrounds. Our hope is that this website and set of resources will help encourage others to embrace R and be empowered to become a member of the R community. 
                </p>
                <Link to="/about"><button>About us</button></Link>
            </section>
        </main>
    )
}