import React from "react";
import Navbar from '../components/Navbar';
import BlogComp from '../components/BlogComp'
import Footer from '../components/Footer';

export default function Blog() {
    return (
        <div className="react-blog-wrapper">
            <Navbar />
            <BlogComp />
            <Footer />
        </div>
    )
}