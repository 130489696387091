import React from "react";

export default function Navbar() {
    return (
        <footer>
                {/* <ul className="footer-list">
                    <li>Home</li>
                    <li>FAQ</li>
                    <li>About</li>
                    <li>R Module</li>
                    <li>Blog</li>
                </ul> */}
                <div className="copyright-div">
                    <small className="copyright">Copyright &copy;2022 R Module. Page by <span className="apiculae-link">apiculae</span></small>
                </div>
        </footer>
    )
}