import './styles/App.css';
import Navbar from './components/Navbar';
import Home from './components/Home'
import Footer from './components/Footer';

function App() {
  return (
    <div className="react-home-wrapper">
    <Navbar />
    <Home />
    <Footer />
    </div>
  );
}

export default App;
