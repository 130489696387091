import React from "react";

export default function AboutComp() {
    return (
        <main className="about-grid">
            <img  src={require ("../images/Image1-About.png")} className="img-1-about" alt="Abstract art for aesthetic purposes" />            
            <div className="about-flex">
                <section className="about-info">
                    <h1>About us</h1>
                    <p>We are two learners and teachers of R who are very passionate about encouraging and supporting others to use R for their statistical analysis! We have created R Module to make learning and using R a positive, confidence-building and enjoyable journey for everyone, with no expectation of statistical or computational backgrounds. Our hope is that this website and set of resources will help encourage others to embrace R and be empowered to become a member of the R community. </p>
                </section>
                <section className="author-info">
                    <h2>Lisa Pilkington</h2>
                    <p>Dr Lisa Pilkington is from Auckland, New Zealand. Lisa completed a BA/BSc conjoint degree from the University of Auckland in 2010, majoring in Chemistry, Statistics and German. Following this, from the same institute, Lisa completed a BSc(Hons) degree (2011)  followed by a PhD (2015) in Chemistry, both under the supervision of Prof. David Barker. Lisa then undertook a Masters in Applied Statistics at the University of Oxford, UK, graduating in 2017, after which she returned to New Zealand to carry out post-doctoral research with the support of a number of Fellowships. In 2020, Lisa was appointed as a Senior Lecturer in chemometrics/chemical data science at the School of Chemical Sciences at the University of Auckland, New Zealand, a position that she currently holds. </p>
                </section>
                <section className="author-info">
                    <h2>Deborah Gutierrez</h2>
                    <p>Deborah Gutierrez is an artist and science enthusiast from Mexico City. After completing a BSc in Food Chemistry from the National Autonomous University of Mexico (UNAM), Deborah was awarded a Manaaki New Zealand Scholarship to study an MSc in Food Science at the University of Auckland, New Zealand (2020). Alongside his scientific career, Deborah has always been interested in the intersection of science, art and design, especially its application in science education.</p>
                </section>
            </div>
            <img  src={require ("../images/Image2-About.png")} className="img-2-about" alt="Abstract art for aesthetic purposes" />
        </main>
    )
}