import React from "react";
import RmodulePDF from ".//../images/20221101 - R Module webpage.pdf"

export default function RmoduleComp() {
    return (
        <main className="Rmodule">
            <img src={require ("../images/Image1-Rmodule.png")} className="img-1-Rmodule" alt="Computer analysing and validating data" /> 
            <img src={require ("../images/Image3-Rmodule.png")} className="img-3-Rmodule" alt="Computer analysing and validating data" /> 
            <section className="Rmodule-info">
                <h1>R Module</h1>
                <p>R Module is an all-in-one resource that does not expect you have had any previous experience with R or programming/coding – we will show and teach you all you need to know. From the contents below, you can see how R Module shows you from how to install R and R Studio, all the way to advanced statistical analysis and graphing techniques. We will also be reviewing and adding content, both in R Module and also in the Blog section of this website, so make sure that you keep an eye out for those. </p>
                <a href={RmodulePDF} download="Rmodule1stEd.pdf"><button>Download pdf</button></a>
            </section>
            <img src={require ("../images/Image2-Rmodule.png")} className="img-2-Rmodule" alt="Abstract figure for decorative purpose" /> 
            <section className="Rmodule-outline">
                <h2>Outline</h2>
                <div>
                    <h3>Section 1: Getting Functional in R</h3>
                    <ol>
                        <li>Installing and setting up R and R Studio.</li>
                        <li>Getting to know R and R Studio.</li>
                        <li>How to start a project and save your work in R.</li>
                        <li>Features in R.</li>
                        <li>Data structures and entering data into R.</li>
                        <li>Exporting output from R.</li>
                    </ol>
                    <h3>Section 2A: Basic Statistical Analysis</h3>
                    <ol>
                        <li>Outlier detection.</li>
                        <li>Calculating descriptive statistics.</li>
                        <li>Calculating confidence intervals.</li>
                    </ol>
                    <h3>Section 2B: Basic Graphing - Data visualisation</h3>
                    <ol>
                        <li>One-dimensional plots.</li>
                        <li>Side-by-side plots.</li>
                        <li>Scatter plots.</li>
                    </ol>
                    <h3>Section 2C: Significance Tests</h3>
                    <ol>
                        <li>T-tests.</li>
                        <li>F-test.</li>
                        <li>ANOVA (Analysis of variance).</li>
                    </ol>
                    <h3>Section 2D: Regression Analysis</h3>
                    <ol>
                        <li>Unweighted linear regression.</li>
                        <li>Weighted linear regression.</li>
                        <li>Linear model graphics.</li>
                        <li>Non-linear regression.</li>
                    </ol>
                </div>
                <div>
                    <h3>Section 3A: Using R for Unsupervised Learning</h3>
                    <ol>
                        <li>Data manipulation and large data set management.</li>
                        <li>Hierarchical clusted analysis (HCA).</li>
                        <li>Principal component cluster analysis (PCA).</li>
                    </ol>
                    <h3>Section 3B: Using R for Supervised Learning</h3>
                    <ol>
                        <li>Creating training and test sets.</li>
                        <li>Random forest models.</li>
                        <li>kNN (k Nearest Neighbours) analysis.</li>
                        <li>Discriminant analysis.</li>
                    </ol>
                    <h3>Section 4: Advanced Graphing - Visual Representation</h3>
                    <ol>
                        <li>ggplot2 package - One-dimensional plots revisited.</li>
                        <li>ggplot2 package - Side-by-side revisited.</li>
                        <li>ggplot2 package - Regression revisited.</li>
                        <li>factoextra package - PCA plots revisited.</li>
                    </ol>
                    <h3>Section 5A: Bringing it all Together</h3>
                    <ol>
                        <li>Statistics workflows.</li>
                        <li>Worked examples.</li>
                    </ol>
                    <h3>Section 5B: Moving Forward</h3>
                    <ol>
                        <li>Debugging your code, interpreting errors.</li>
                        <li>Online community - R help forums.</li>
                        <li>Available data repositories - recommended data sets.</li>
                    </ol>
                </div>
            </section>
        </main>
    )
}