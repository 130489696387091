import React from "react";
import Navbar from '../components/Navbar';
import RmoduleComp from '../components/RmoduleComp'
import Footer from '../components/Footer';

export default function Rmodule() {
    return (
        <div className="react-Rmodule-wrapper">
            <Navbar />
            <RmoduleComp />
            <Footer />
        </div>
    )
}