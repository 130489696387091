import React from "react";
import Navbar from '../components/Navbar';
import AboutComp from '../components/AboutComp'
import Footer from '../components/Footer';

export default function About() {
    return (
        <div className="react-about-wrapper">
            <Navbar />
            <AboutComp />
            <Footer />
        </div>
    )
}