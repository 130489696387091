import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
    const [isNavOpen, setIsNavOpen] = React.useState(false);

    function navClick() {
        setIsNavOpen(prevNav => !prevNav)
    }

    return (
        <nav> 
            <div className="navbar">
                <div className="nav-column-1">
                    <img src={require ("../images/Logo-Navbar.png")} className="logo" alt="Rmodule Home" />
                    <div className="burger-div" onClick={navClick}>
                        <div className={isNavOpen? "clicked-line-1" : "line-1"}></div>
                        <div className={isNavOpen? "clicked-line-2" : "line-2"}></div>
                        <div className={isNavOpen? "clicked-line-3" : "line-3"}></div>
                        <div className={isNavOpen? "clicked-line-4" : "line-4"}></div>
                    </div>
                </div>
                <div className={isNavOpen? "nav-column-2" : "closed-nav"}>
                    <ul className="nav-list">
                        <li className="nav-item"><Link to='/'>Home</Link></li>
                        <li className="nav-item"><Link to="/about">About</Link></li>
                        <li className="nav-item"><Link to="/rmodule">Rmodule</Link></li>
                        <li className="nav-item"><Link to="/blog">Blog</Link></li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}